.upload-modal-body {
  /* height: 20rem; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  /* width: 50rem; */
}

.upload_btn__container {
  min-height: 10rem;
  padding: 0.5rem;
}

.upload_file_buttons {
  gap: 4rem;
}

.file-size-error {
  font-size: 1.25rem;
}

.upload_btn {
  padding: 8px !important;
  width: 37px !important;
  /* height: 37px !important; */
  min-width: 0 !important;
}

.upload-file-input {
  display: none;
}
