.pieChart__heading,.charts__heading,.styleInventory__heading{
    margin: auto;
    padding: 2rem;
    font-weight: bold;
}

.pieChart__container{
    display: flex;
    max-width: 100%;
    flex-wrap: wrap;
    /* justify-content: space-between; */
    justify-content: center;
}

.pieChart__legends{
    display: inline-flex;
    margin: auto;
}

.pieChart__legends__list{
    color: #333;
    list-style: none;
    text-align:left;
    display: flex;
    flex-direction:row;
    margin: 8px;
}

.overlay{
    width: 100%;
    height: 100%;
    opacity: .2;    
    text-align: center;
}

.overlay div {
    font-size: 3.4rem;
  }