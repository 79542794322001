.section_container {
    background-color: #fff;
    padding: 1rem 1rem 1.5rem;
}

.empty {
    color: rgb(0 0 0 / 62%);
    font-size: 2rem;
    margin: 20px auto;
    padding: 10px;
    width: max-content;
}

.empty p {
    text-align: center;
    font-size: 1rem;
    font-weight: 500;
    color: rgb(0 0 0 / 62%);
}

.set_strategy_container {
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 24px 18px 24px 10px;
    width: 100%;
    max-width: 100%;
}

#PRE_DEMAND_TYPE_SELECTOR, #IN_DEMAND_TYPE_SELECTOR, #CLEARANCE_DEMAND_TYPE_SELECTOR {
    margin: 0% 0% 1% 0%;
    height: 3.5rem;
    width: 15rem;
}

#PRE_FREQUENCY_SELECTOR, #IN_FREQUENCY_SELECTOR, #CLEARANCE_FREQUENCY_SELECTOR {
    margin: 0% 0% 1% 0%;
    height: 3.5rem;
    width: 15rem;
}

.non-editable-labels {
    font-weight: 600;
    font-size: 1.2rem;
    width: 16%;
}

.editable-text-field {
    height: 3.0rem;
}

.execution-date-cal {
    height: 90%;
    width: 35%;
    margin-left: 2%;
}

.execution-date-cal > input {
    text-align: center;
    opacity: 1;
    font-weight: 530;
    color: black !important;
    line-height: 1.8;
    font-size: 1.1rem;
}

.execution-date-cal .p-inputtext:enabled:focus {
    box-shadow: none;
    border-color: #ced4da;
}

.execution-date-cal .p-inputtext:enabled:hover {
    border-color: #ced4da;
}

.execution-date-cal .p-button:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
}

.execution-date-cal .p-button:enabled:hover {
    background: #0000002b;
    color: #ffffff;
    border-color: inherit; 
}