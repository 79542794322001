.marquee {
    width: inherit;
	line-height: 30px;
    white-space: nowrap;
    overflow: hidden;
    box-sizing: border-box;

}
.marquee p {
    font-size: 1.2rem;
    font-style: italic;
    margin: 0;
    display: inline-block;
    padding-left: 100%;
    animation: marquee 30s linear infinite;
}
@keyframes marquee {
    0%   { transform: translate(0, 0); }
    100% { transform: translate(-100%, 0); }
}

.marquee p:hover {
    animation-play-state: paused;
}