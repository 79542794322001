/*----------------login css------------------*/
#leftImgdiv {
  background-size: cover;
  background-position: center;
}
#forgtpas {
  float: right;
}
#btndiv-login {
  text-align: center;
  padding: 10px;
  width: 100%;
}
#promoColor1 {
  color: #4f677b;
  font-weight: 600;
}
.navTopLeft {
  padding-top: 14px;
}
.form-label{
  font-size: 1.5rem;
}
#subdiv-login {
  margin-top: 60px;
}
p.text-white.copyrighttext {
  text-align: center;
}
button.btn.btn-primary.btn-square.shadow-sm.btncontact {
  padding-right: 42px;
  padding-left: 40px;
  margin-top: 40px;
  font-weight: 700;
  background-color: #44677b;
}
#divRight {
  background-color: #fbfbfb !important;
  justify-content: center;
  align-items: center;
  display: flex;
}
div#signinform {
  width: 60%;
  margin: 250px auto;
}

.mfa__container {
  margin-top: 50px;
}

form.border.rounded.p-5.formWidth {
  background-color: #ffffff;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.4);
}
.text-cyan {
  color: #44677b;
}
button.btn.btn-primary.btn-square.shadow-sm.btnsignin {
  padding: 10px 40px;
  background-color: #44677b;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
}
input#loginInputEmail1 {
  padding: 23px 12px;
}
input#loginPassword {
  padding: 23px 12px;
}
.btn-sso {
  font-size: 16px !important;
  padding: 4px 40px !important;
}
@media (min-width: 768px) {
  .h-md-100 {
    height: 100vh;
  }
}
@media only screen and (max-width: 480px) {
  h4#signIntext {
    font-size: 16px;
  }
}
