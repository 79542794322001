.product__profile_container, .create__edit__product__profile_container {
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 24px 18px 24px 10px;
    width: 100%;
    max-width: 100%;
}

a.nav-item.nav-link {
    border: none;
    font-size: 1.3rem;
    color: var(--color-neutral-a-s-b) !important;
}

.nav-tabs{
    margin-left: 15px !important;
}

.profiletable__container{
    align-self: center;
    /* margin: 4rem 2rem 2rem !important; */
    max-width: 100%;
}

.profiletable__tabs{
    width: 100%;
    display: flex;
    flex-direction: column;
}

.productprofile_tables{
    display: flex;
    flex-direction: row;
}

.productprofile__table{
    align-self: center;
    margin: 2rem 0 !important;
    /* max-width: 60%; */
}

.productprofile__stylecolor_description_table{
    /* margin: 2rem 0 2rem 2rem !important; */
    /* max-width: 40%; */
    margin: 4rem auto;
    max-width: 96%;
}

.top__filters, .set__attributes__filters, .profiled__products, .select__products {
    margin: 2rem ;
}

.profile__toggle{
    margin: 4rem 0 !important;
    text-align: center;
}

.profile__toggle > div > span {
    font-size: 1.35rem ;
    font-weight: 700 ;
}

.create__edit_product_profile_proceed__button > button {
    margin: 0 1rem;
}

.DateInput_input{
   padding: 5px 10px 5px 10px !important;
   font-size: 12px !important;
}

.DateInput{
    width: 100px !important;
}

.DateRangePickerInput__showClearDates{
    padding-right: 18px !important;
}

.set__attributes__filters__price{
    display: flex;
}

.set__attributes__filters__price > div {
    margin: 0 1rem;
}

.set__attributes__filters__price > div > input {
    width: 10rem;
    height: 3rem;
    font-size: 1.5rem;
}

.set__attributes__filters__price > div > label {
    font-size: 1.5rem;
}

.sister-store__map {
    background-color: lightgrey;
}

.indicate-div {
    width: 25px;
    height: 15px;
}