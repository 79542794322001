.section_container {
    background-color: #fff;
    padding: 1rem 1rem 4.5rem;
    margin: 1rem;
}

.date-range-label {
    font-weight: 600;
    font-size: 1.2rem;
    width: 16%;
}

.range-date-cal {
    height: 100%;
}

.inv-src-labels {
    font-weight: 600;
    font-size: 1.2rem;
    width: 16%;
    margin-top: 1%;
}

.inv-source-container {
    margin: 0% 0% 1% 3%;
}

.inventory-drop-table {
    margin: 3% 0% 2% 2%; 
}

#INVENTORY_SOURCE_SELECTOR > div {
    width: 50%;
}

#INVENTORY_SOURCE_SELECTOR > div > button {
    height: 3rem;
}