html {
  /* Font-size 1rem = 10px instead of 16px. Easier to calculate for the human mind */
  font-size: 62.5%;
}

/* global variables */

:root {
  --color-primary: #44677b;
  --color-secondary-e-blue: #9ed1eb;
  --color-seondary-s-g: #83c48d;
  --color-secondary-m-c: #efcb53;
  --color-neutral-t-b: #57788b;
  --color-neutral-a-s-b: #71a2c1;
  --color-neutral-d-g: #4d4d4d;
  --color-neutral-a-b: #f0f4f7;
  --color-semantic-m-s-g: #4cb976;
  --color-semantic-c: #ccbd00;
  --color-semantic-e-v: #d84c4c;
}

/* Reset padding margin and box sizing */

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

/* typography */

.fnt-lg {
  font-size: 1.8rem;
}

.fnt-md {
  font-size: 1.6rem;
}

.fnt-sm {
  font-size: 1.4rem;
}

.fnt-bold {
  font-weight: 700;
}

.fnt-semi-bold {
  font-weight: 600;
}

.fnt-med {
  font-weight: 500;
}

.fnt-norm {
  font-weight: 400;
}

/* links */

a:hover, a:visited, a:focus, a:active {
  text-decoration: none;
}

/* buttons */

.btn {
  font-size: 1.4rem;
  font-weight: 500;
  min-width: 8rem;
  padding: 1.6rem 0;
  height: 3.2rem;
}

.btn-primary {
  background: var(--color-primary);
  color: #fff;
}

.btn-primary:hover {
  background: #395b6f;
}

.btn-primary:active {
  background: #50778e;
}

.btn-primary-disabled {
  background: #ccdfea;
}

.btn-secondary {
  background: #fff;
  color: var(--color-primary);
  border: 1px solid var(--color-primary);
}

.btn-secondary:hover {
  background: #d6e2e8;
}

.btn-secondary:active {
  background: #8dced6;
}

.btn-secondary-disabled {
  border: 1px solid #bfbfbf;
  color: #bfbfbf;
}

.btn-primary-rounded {
  background-color: #44677b !important;
  opacity: 0.9;
  color: #fff;
  height: 45px;
  width: 45px;
  border-radius: 50%;
  border: none;
  display: inline-block;
}

.btn-progress {
  float: right;
  color: #FB5659 !important;
  padding: 0 1.6rem !important;
  border: 1px solid #FB5659 !important;
}

.btn-progress:hover {
  background: #fff2e2;
}

.btn-progress:active {
  background-color: #F0EFF0 !important;
  color: #6d6666 !important;
  border: 1px solid #6d6666 !important
}

.btn-progress.active {
  background-color: #F0EFF0 !important;
  color: #6d6666 !important;
  border: 1px solid #6d6666 !important
}