.constraints__container{
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 24px 18px 24px 10px;
    width: 100%;
    max-width: 100%;
}

.constraints__body__table{
    background-color:#fff;
    padding: 1rem;
    margin: 1rem ;
    margin-top: 2rem;
}

.constraints__body__radio{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin:10px 0;
}

.constraints__body__radio__message{
    flex: auto;
}

.constraints__storeGroup,.constraints__storeGrade,.constraints__store{
    cursor: pointer;
    color: var(--color-neutral-a-s-b);
    text-decoration: underline
}

.btn.button.btn-primary.center {
    margin: auto !important;
    /* width: 30px; */
}

.constraints__apply__button{
    display: flex;
    margin-top: 1rem;
}