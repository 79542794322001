.reporting-filters {
    flex-direction: column;
}

.filter-heading {
    font-size: 2rem;
}

.col-md-3-dd {
    flex: 0 0 20%;
    max-width: 20%
}

.section {
    /* margin: 0; */
}

.filter {
    margin: 0;
}

.week-filter {
    outline: none;
    padding: 0.5rem 1rem;
}

.week-filter > option {
    font-size: 14px;
}

.excess-report-container {
    background-color: #fff;
    padding: 2rem;
    display: flex;
    flex-direction: column;
}

.detail__heading {
    padding: 1rem;
    font-weight: bold;
}

.detail__container {
    display: flex;
    border: 1px solid #007bff;
    font-weight: bold;
    color: white !important;
    background-color: var(--color-primary) !important;
    margin-bottom: 1rem;
    /* margin-top: -60px; */
    border-radius: 3px;
    width: fit-content;
}

.excess-graph__container {
    width: 90%;
    margin: auto;
}

#Week-filter {
    height: 3rem;
    width: 13rem;
}

.deepdive__export__wrapper {
    overflow: auto;
}

.deepdive__export__wrapper > button {
    float: right;
}

.filter__heading {
    padding: 1rem 0 0 1rem;
}
