.filter {
    background-color: #fff;
    padding: 1rem 1rem 4.5rem;
    margin: 1rem;
}

.view_allocation__tables{
    display: flex;
    flex-direction: column;
}

 
.view_allocation__table{
    align-self: center;
    margin: 20px 0 0 20px;
    max-width: 94%;
}