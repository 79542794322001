.card__wrapper{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-evenly;
}

.card__container {
  margin: 3rem 0;
  padding: 3rem 0;
  text-align: center;
  width: 240px;
  box-sizing: border-box;
  transition: transform 450ms ease-in-out;
  box-shadow: 0 5px 10px 0 rgba(0,0,0,0.5);
  border-radius: 15px;
  background-color: whitesmoke;
  font-weight: bold;
}

.card__container:hover {
  transform: scale(1.1); 
}

.card__container > span {
  font-size: small;
}

.card__container > h4 {
  padding-bottom: 1rem;
  color: gray;
}
