.slider {
    margin-bottom: 4rem;
  }
  
  .slider label {
    color: #888;
    font-size: 0.8rem;
    margin-bottom: 20px;
    display: block;
    text-transform: capitalize;
  }
  
  .input-range__slider {
    background: var( --color-primary);
    border: none;
  }
  
  .input-range__track {
    background: #ddd;
  }
  
  .input-range__track--active {
    background: var(--color-neutral-a-s-b);
  }
  
  .input-range__label--value .input-range__label-container {
    color: var( --color-primary);
    font-size: 1.5rem;
    padding: 5px 5px;
    position: relative;
    bottom: 1.5rem !important;
  }

  .input-range__label-container::before{
      content: '$';
  }
  
  .input-range__label--min .input-range__label-container,
  .input-range__label--max .input-range__label-container
   {
    font-size: 1.5rem;
    color: var( --color-primary);
    position: relative;
    top: 1rem !important;
    left: 0;
  }
  
  .input-range__label--max .input-range__label-container {
    left: 25%;
  }
  