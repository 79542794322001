.finalize__container{
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 24px 18px 24px 10px;
    width: 100%;
    max-width: 100%;
}

.store__view__tables {
    display: flex;
    flex-direction: column;
}

.f__table {
    margin: 20px 0;
    width: 100%;
}

.finalize__container__button {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.finalize__btn {
    margin-bottom: 2rem;
}

.finalize__tabs__body {
    position: relative;
}

.back__btn {
    position: absolute;
    right: 0;
    margin: 1rem;
    margin-top: 2rem;
}

.plan__details {
    font-size: 14px;
}

.row-span2__column {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.border-bottom__td {
    border-bottom: 1px solid #ddd;
}

.highlight_row {
    background-color:lightgrey;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center
}

.custom-indicator__styles {
    justify-content: flex-end;
    margin-top: 10px;
}