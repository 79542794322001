.modal_container {
    background: rgba(57, 73, 96, 0.6);
    position: fixed;
    inset: 0px;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    z-index: 10003 !important;
}

.modal-body__container {
    margin: auto;
    background: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;
    width: 735px;
    max-height: 444px;
    /* margin-top: 10rem; */
}

.modal-header__container {
    height: 50px;
    background: rgb(83, 91, 99);
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    justify-content: space-between;
}

.modal-header__text {
    color: rgb(255, 255, 255);
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
}

.modal-content__container {
    padding: 20px;
    box-sizing: border-box;
    overflow: auto;
}

.modal-close__button {
    display: inline-block;
    width: 16px;
    height: 16px;
    cursor: pointer;
    background: url(data:image/svg+xml,%3Csvg%20width%3D%2212%22%20height%3D%2213%22%20viewBox%3D%220%200%2012%2013%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M11.8337%201.84199L10.6587%200.666992L6.00033%205.32533L1.34199%200.666992L0.166992%201.84199L4.82533%206.50033L0.166992%2011.1587L1.34199%2012.3337L6.00033%207.67533L10.6587%2012.3337L11.8337%2011.1587L7.17533%206.50033L11.8337%201.84199Z%22%20fill%3D%22white%22%2F%3E%3C%2Fsvg%3E) 0% 0% / 16px no-repeat;
}

.modal-buttons__container {
    height: 65px;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    padding: 20px;
    box-sizing: border-box;
    gap: 16px;
    -webkit-box-pack: end;
    justify-content: flex-end;
}