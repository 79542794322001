.flex-wrap {
    display: flex;
    flex-wrap: wrap;
}

.detail__div {
    font-size: 13px;
}

.disable__column {
    opacity: 0.5;
    pointer-events: none;
}