.navbar {
  padding: 0.2rem 1rem 0.2rem 2rem;
  background: #ffffff !important;
  border: none;
  border-radius: 0;
  margin-bottom: 10px;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
  height: 72px;
  z-index: 800;
  top: 0;
  position: fixed;
  width: 100%;

  &-nav {
    flex-direction: row;
    align-items: center;
  }

  &-logo {
    font-size: 2.2rem;
    text-transform: uppercase;
    color: var(--color-primary);
    margin: 0 auto;
  }

  &-menu-right {
    float: right;
  }
}

.nav-item {
  padding: 0.75rem;

  &-btn {
    background: var(--color-primary);
    width: 4rem;
    color: #fff;
    text-align: center;
    line-height: 4rem;
  }

  &-username {
    color: var(--color-neutral-d-g);
  }

  & > .icon-header {
    font-size: 2rem;
    color: var(--color-primary);
  }
}

li.nav-item {
  padding-right: 22px;
}
