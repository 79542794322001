.table-set {
    display: block;
    font-size: 1.3rem;
    overflow-x: auto;
    border-collapse: collapse
}

.th-set {
    width: 150px;
    font-weight: bold;
    color: white !important;
    background-color: #44677b;
    text-align: center;
    height: 6vh;
}

.tr-set {
    border: 1px solid #ddd;
}

.td-set {
    align-items: center;
    min-height: 38px;
    line-height: 2.5;
    text-align: center;
    background-color: #fff;
    color: black !important;
    font-weight: 400;
    width: 100px;
    min-width: 100px;
    max-width: 100px;
    white-space: break-spaces;
}

.span-data {
    display: inline-block;
    width: 50%;
    background-color: #a6d5fa63;
    margin: 5% 0% 5% 0%;
    color: #0000009c;
}

.sticky-col {
    position: sticky;
    min-width: 100px;
    width:100px;
    left: 0;
    text-align: left;
}

.sticky-col2 {
    position: sticky;
    min-width: 150px;
    width:150px;
    left: 100px;
    text-align: center;
}

.sticky-col3 {
    position: sticky;
    min-width: 150px;
    width: 150px;
    left: 250px;
    text-align: center;
}

.edit-span {
    display: inline-block;
    width: 50%;
    background-color: #a6d5fa00;
    margin: 5% 0% 5% 0%;
    color: #0000009c;
    border: 1px solid #a6d5fa63;
}
  
.hierarchy-table {
    max-height: 80vh;
    max-width: 95%;
    overflow-y: auto;
    overflow-x: auto;
}

.sticky-header {
    position: sticky;
    top: 0;
    position: sticky;
    min-width: 100px;
    width:100px;
    left: 0;
    text-align: left;
    z-index: 2;
}

.hierarchy-table th.sticky-col, .hierarchy-table td.sticky-col {
    text-align: center;
}
.grid_height {
    min-height: 4.9rem;
}

.edit-span-2 {
    display: inline-block;
    /* width: 50%; */
    background-color: #a6d5fa00;
    margin: 5% 0% 5% 0%;
    border: 1px solid #ffff;
    padding: 0.5rem 0.5rem;
    border-radius: 0;
    text-align: center;
}

.header-back-color {
    background-color: #44677b;
}

.table-input-field {
    width:90%;
    margin:4px;
    border-radius:2rem;
    padding-left:5px;
    outline:none;
    border-color:#343a40;
}

.hidden {
    display: none;
}


.my-paginated-list {
    display: flex;
    flex-direction: column;
    align-items: center;
}
  
.pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}
  
.pagination li {
    margin: 0 5px;
    cursor: pointer;
}
  
.pagination li a {
    display: block;
    padding: 10px;
    border: 1px solid #ccc;
    background-color: #44677b;
    color: #fff !important;
}
  
.pagination li.active a {
    background-color: #000;
    color: #fff;
}
  
.pagination li a:hover {
    background-color: #395b6f;
}

/* .table-container {
    min-height: 55rem;
} */
    
.sub-table {
    border-collapse: collapse;
    table-layout: fixed;
}