$configure-button-width:120px;

.user__management__table{
    display: flex;
    align-items: center;
    justify-content: center;
}

.user_management__container{
    position: relative;
    padding: 24px 18px 24px 10px;
    width: 100%;
    max-width: 100%;
}

.user_management__config_button{
    min-width: 12rem;
}

.user_management__container_body{

    background-color: white;
    box-shadow: 1px 2px rgba(0, 0, 0, 0.1)
}



.user_management__align_baseline{
    align-items: baseline;
    padding: 25px 25px 10px 25px;

    button .btn_user_management_action{
        //min-width: 12rem;
        width: 100%;
        
    }

    input[type=search]{
        font-size: 12px !important;
        padding: 2px 8px !important;
        line-height: 23px;
        height: 32px;
    }
}

.user_management__search_icon {
    button {
        border: 1px solid #ced4da;
        border-left: white;
        min-width: auto;
        font-size: 12px;
    }
}

