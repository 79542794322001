.modal-body{
    display: flex;
    flex-direction: column;
    align-items: stretch;

}

.constraintsModal__Table{
    margin: auto;
    margin-top: 2rem;
}