.storeAndDC {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ted__sum {
  font-size: 13px;
  font-weight: bold;
}

.table {
  --background-pink: #f2f2f2;
  --background-blue: #ebf6ff;
  --background-peach-yellow: #ffffb9;
}

.strategyContainer{
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 24px 18px 24px 10px;
  width: 100%;
  max-width: 100%;
}

.strategyHeader {
  font-size: 2rem;
  padding: 1rem;
  font-weight: 700;
}

.card {
  background: #fff;
  padding: 1rem;
  margin: 1rem;
}

.filter {
  margin: 0 1rem;
}

.multiselectDiv {
  width: 100%;
}

.cell {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.storeInfoCol {
  background: var(--background-pink);
}

.storeFlagInfoCol {
  background: var(--background-peach-yellow);
}

.productProfileCol {
  background: var(--background-blue);
}

// .multiselectDiv > div {
//   position: static !important;
// }

.table {
  table {
    margin: auto;
  }
}

.toggleComponent {
  text-align: right;
  margin: 1rem 0;
  font-size: 1.3rem;
}

.adjacent__buttons{
  margin: 2px 0.5rem !important;
  top: 0 !important;
}

