.lost_sales_report_container {
    background-color: #fff;
    padding: 2rem;
    margin: 1rem;
}
.lost_sales_graph_container {
    background-color: #fff;
    padding: 1rem 1rem 4.5rem;
    margin: 1rem;
    width: 100%;
}
.lost_sales_details_table_container {
    background-color: #fff;
    padding: 1rem 1rem 4.5rem;
    margin: 1rem;
}

.lost_sales_graph_view {
    padding: 1.2%;
    margin-top: 1%;
    background-color: #a6d5fa40;
    width: 100%;
}

.total_lost_opportunity{
    display: flex;
    border: 1px solid #007bff;
    font-weight: bold;
    color: white !important;
    background-color: var(--color-primary) !important;
    margin-bottom: 1rem;
    border-radius: 3px;
    width: fit-content;
}

#WEEK_SELECTOR {
    margin-left: 10%;
    height: 3rem;
    width: 13rem;
}

#LINE_CHART_LOST_SALES {
    background-color: #fff;
    border: 2px solid #fff;
    border-radius: 2px;
}

.empty {
    color: rgb(0 0 0 / 62%);
    font-size: 2rem;
    margin: 20px auto;
    padding: 10px;
    width: max-content;
}

.empty p {
    text-align: center;
    font-size: 1rem;
    font-weight: 500;
    color: rgb(0 0 0 / 62%);
}

.graph-toggle-container {
    display: flex;
    justify-content: flex-end;
}

.graph-toggle-label {
    font-weight: bold;
    font-size: 1.2rem;
    margin-right: 5px;
}